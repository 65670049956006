.sign-up {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-up-headline {
    font-size: 2rem;
    text-align: center;
    font-weight: 700;
    margin-top: 50px;
}

.sign-up-notifications {
    font-weight: 600;
    width: 300px;
    margin-bottom: 35px;
    line-height: 1.9;
}

.submit-button {
    margin-top: 35px;
    margin-bottom: 100px;
}

.sign-up-subheadline {
    font-size: 1.5rem;
    font-weight: 800;
    text-align: left;
    width: 300px;
    margin-top: 70px;
    margin-bottom: 35px;
}

.sign-up-subtext {
    max-width: 300px;
    font-weight: 600;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    margin-bottom: 35px;
    line-height: 1.9;
}

.sign-up-inputs {

    label {
        margin-top: 35px;
    }

    width: 300px;
    display: flex;
    flex-direction: column;
}