.button {
    border-radius: 300px;
    height: 50px;
    width: 150px;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
    font-weight: 800;
    cursor: pointer;
    transition: transform .2s ease-out, color .2s ease-out;
    font-size: 14px;

    a {
        height: 100%;
        width: 100%;
        text-decoration: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.button:hover {
    transform: scale(1.05)
}