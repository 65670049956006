.dropdown {
    width: 300px;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dropdown-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.dropdown-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 2;
    height: 100%;
}

.dropdown-selected {
    font-weight: 800;
    font-size: 24px;
    margin-left: 5px;
    margin-right: 5px;
}

.triangle {
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid #c4c4c4;
    border-radius: 2px;
    margin-left: 5px;
    margin-right: 5px;
}

.dropdown-label {
    font-weight: 600;
    text-align: left;
    font-size: 18px;
    margin-bottom: 10px;
    width: 100%;
    margin-left: 20px;
}

.dropdown-options {
    z-index: 9999;
    position: absolute;
    margin-top: 46px;
    background-color: #ffffff;
    width: 300px;
    opacity: 0;
    display: flex;
    max-height: 300px;
    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    border-radius: 5px;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    animation: fadeIn .2s ease-in-out forwards;

    .dropdown-option { 
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 18px;
        font-weight: 600;
        min-height: 23px;
        width: 100%;
        text-align: center;
        transition: background-color .2s ease-in-out, color .2s ease-in-out;

        &:hover {
            background-color: #8B16D3;
            color: #fafafa;
        }
    }

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
