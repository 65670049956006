.footer {
    background-color: #20232a;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;

    &-elements {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        @media screen and (max-width: 700px){
            justify-content: center;
            flex-direction: column;
        }

    }

    svg {
        max-height: 75px;

        @media screen and (max-width: 700px){
            margin-bottom: 35px;
        }
    }

    &-terms {
        color: #ffffff;
        font-size: 12px;
        font-family: 'Quicksand', sans-serif;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 400px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 25px;
        padding-top: 50px;

        @media screen and (max-width: 400px){
            width: 100%;
            font-size: 10px;
        }

    }

    &-menu {
        display: flex;
        flex-direction: column;
        color: #ffffff;
        font-family: 'Quicksand', sans-serif;
        text-align: center;
    }

    &-menu-header {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    &-menu-item {
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 5px;
        cursor: pointer;
    }

    &-list {
        display: flex;
        flex-direction: row;
        width: 500px;
        justify-content: space-evenly;
        padding-bottom: 75px;

        @media screen and (max-width: 700px){
            width: 100%;
        }

    }

}

