@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    overflow-x: hidden;
    color: #20232a;
    font-family: 'Quicksand', sans-serif;
}

.app-body {
    margin-top: 75px;
}