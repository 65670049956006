.profile-banner {
    background-image: linear-gradient(to right, #8B16D3, #1679D3);
    width: 100%;
    height: 250px;
    overflow: hidden;
    opacity: 0;
    animation: fadeIn .5s 1.3s ease-in-out forwards;

    img {
        width: 100%;
        min-height: 100%;
    }

    input {
        position: absolute;
        opacity: 0;
        height: 250px;
        width: calc(100% + 300px);
        cursor: pointer;
        left: -300px;
    }

    input:hover {
        opacity: .5;

        transition: opacity .2s ease-out;
        background-color: #111111;
        border-radius: 1px solid #111111;
    }

}

.profile {
    min-height: 100vh;
}

.profile-loaded {
    opacity: 0;
    animation: fadeIn .3s ease-in-out forwards;
}

.profile-content {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 800px){
        flex-direction: column;
        align-items: center;
    }

}

.profile-search {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    justify-content: center;
    flex-wrap: wrap;
}

.profile-info {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 800px){
        width: 100%;
    }
}

.profile-image {
    height: 250px;
    width: 250px;
    border-radius: 300px;
    background-color: #ffffff;
    border: 5px solid #eeeeee;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    position: absolute;
    top: 150px;
    opacity: 0;
    animation: fadeUp .5s .2s ease-in-out forwards;
    overflow: hidden;

    img {
        width: 250px;
        border-radius: 300px;
        height: 250px
    }

    input {
        position: absolute;
        opacity: 0;
        height: 250px;
        width: 500px;
        cursor: pointer;
        border-radius: 300px;
        left: -50px;
    }

    input:hover {
        opacity: .3;
        transition: opacity .2s ease-out;
        background-color: #111111;
        border-radius: 1px solid #111111;
    }


}

.profile-card {
    
    transition: transform .2s ease-in-out;
    cursor: pointer;

    &:hover {
        transform: scale(1.05)
    }

    img {
        max-height: 150px;
    }

}

.profile-text {
    margin-top: 100px;
}

.profile-username {
    text-align: center;
    margin-bottom: 25px;
    &-header {
        font-weight: 600;
        margin-bottom: 10px;
    }
}

.input-pencil {
    margin-left: 10px;
    cursor: pointer;
    transition: transform .2s ease-in-out;

    &:hover {
        transform: scale(1.30)
    }
}

.profile-about {
    text-align: center;

    &-header {
        font-weight: 600;
        margin-bottom: 10px;
    }

    &-description {
        margin-bottom: 10px;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 250px;
        text-align: center;

        textarea {
            font-family: 'Quicksand', sans-serif;
            border: none;
            font-size: 16px;
            padding: 0;
            outline: none;
            text-align: center;
            resize: none;
            width: 210px;
        }

    }
}

.add-card {
    cursor: pointer;
    margin-top: 50px;
}

.profile-card-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .profile-card {
        margin-left: 10px;
        margin-right: 10px
    }

}

.profile-collection {
    width: calc(100vw - 500px);
    background-color: #fafafa;
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    animation: fadeUp .5s 1.5s ease-in-out forwards;
    border-left: 2px solid #eeeeee;

    &-header {
        margin-top: 35px;
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 35px;
    }

    @media screen and (max-width: 800px){
        border-left: unset;
        width: 100%;
    }
    

}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeUp {
    from {
        opacity: 0;
        margin-top: 10px;
    }
    to {
        opacity: 1;
        margin-top: 0px;
    }
}