.landing-header {
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    padding-left: 50px;
    padding-right: 50px;
    margin-right: auto;
    align-items: center;
    height: 75vh;
    justify-content: space-between;

    @media screen and (max-width: 1100px){
        flex-direction: column;
        margin-top: 50px;
        margin-bottom: 150px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: unset;
    }

}

.landing-info-button {
    margin-top: 50px;
    margin-bottom: 50px;
}

.landing-submission {
    margin-top: 100px;
    margin-bottom: 100px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        justify-content: center;
    }

}

.landing-submission-block {
    @media screen and (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.landing-submission-input {
    margin-bottom: 25px;

    @media screen and (max-width: 1200px) {
        margin-top: 35px;
    }

}

.landing-submission-headline {
    font-weight: 700;
    font-size: 1.5rem;
    font-family: 'Quicksand', sans-serif;
    margin-bottom: 35px;

    @media screen and (max-width: 1200px) {
        text-align: center;
    }

}

.landing-submission-subtext {
    font-family: 'Quicksand', sans-serif;
    line-height: 1.9;

    @media screen and (max-width: 1200px) {
        text-align: center;
        margin-left: 25px;
        margin-right: 25px;
    }

}

.landing-submission-text {
    max-width: 500px;
}

.landing-submission-input {
    max-width: 500px;
}

.landing-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    opacity: 0;
    animation: float-right 1s .6s ease-out forwards;

    @media screen and (max-width: 1100px){
        width: 500px;
    }

    @media screen and (max-width: 500px){
        width: 100%;
    }

}

.landing-info-panel {
    margin-top: 50px;
}

.landing-info-text {
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: linear-gradient(to bottom, #ffffff, #eeeeee)
}

.landing-info-headline {
    font-size: 2rem;
    font-weight: 700;
    max-width: 500px;
    font-family: 'Quicksand', sans-serif;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;

    @media screen and (max-width: 500px) {
        max-width: 300px;
    }   

}

.landing-info-subtext {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.9;
    font-family: 'Quicksand', sans-serif;

    @media screen and (max-width: 500px) {
        max-width: 300px;
    }   

}

.trading-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #20232a;
}

.trading-info {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.trading-sub-title {
    font-size: 18px;
    font-weight: 700;
    font-family: 'Quicksand', sans-serif;
    margin-bottom: 10px;
}

.trading-title {
    font-size: 2.5rem;
    font-weight: 800;
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    font-family: 'Quicksand', sans-serif;
}

.landing-subtitle {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    margin-top: 25px;
    margin-bottom: 25px;
    line-height: 1.9;
    opacity: 0;
    animation: float-in 1s .3s ease-out forwards;
}

.button-landing {
    opacity: 0;
    animation: float-in 1s .3s ease-out forwards;
}

.landing-text {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    color: #20232a;

    @media screen and (max-width: 1100px){
        margin-bottom: 50px;
        margin-top: 75px;
    }

    @media screen and (max-width: 500px){
        margin-top: 50px;
    }

}

.landing-headline {
    font-size: 4rem;
    font-family: 'Quicksand', sans-serif;
    font-weight: 800;
    animation: float-in 1s ease-out forwards;
}

@keyframes float-in {
    from {
        opacity: 0;
        transform: translateY(-35px)
    }
    to {
        opacity: 1;
        transform: translateY(0px)
    }
}

@keyframes float-right {
    from {
        opacity: 0;
        transform: translateX(-35px)
    }
    to {
        opacity: 1;
        transform: translateX(0px)
    }
}