.confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirm-circle {
    margin-top: 50px;
    border: 1px solid #eeeeee;
    width: 150px;
    height: 150px;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-radius: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.confirm-headline {
    font-size: 2rem;
    font-weight: 700;
    margin-top: 35px;
    margin-bottom: 35px;
    text-align: center;
}

.confirm-subtext {
    max-width: 500px;
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    line-height: 1.9;
    margin-bottom: 35px;
}

.verify-headline {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 25px;
}

.verify-subtext {
    text-align: center;
    font-weight: 700;
    margin-bottom: 25px;
}