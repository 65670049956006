.sign-in {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sign-in-image{
    width: 100%;
    height: 250px;

    .deck-trade-open {
        height: 250px;
        width: 100%;
        background-image: linear-gradient(to right, #8B16D3, #1679D3);
    }

    .deck-trade-logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg {
            margin-top: 50px;
            max-height: 100px;
        }

    }

    .deck-trade-sign-in-text {
        color: white;
        text-align: center;
    }

}

.sign-in-section {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .sign-in-headline {
        font-size: 1.5rem;
        text-align: center;
        font-weight: 700;
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .sign-in-input {
        margin-top: 25px;
        margin-bottom: 25px;
        width: 300px;
    }

    .sign-in-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
    }

    .sign-in-error {
        margin-top: 35px;
        margin-bottom: 150px;
        text-align: center;
        font-weight: 600;
        color: #E41818;
    }

}
