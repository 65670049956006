.loading {
    position: fixed;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    outline: 9999px;
    z-index: 9999;
    top: 0px;
    opacity: 0;
    animation: fadeIn .5s ease-out forwards;
}

.loading-modal {
    width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.loading-dot {
    height: 30px;
    width: 30px;
    border-radius: 300px;
    animation: jumpUp .4s ease-in-out infinite alternate;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@keyframes jumpUp {
    from {
        margin-top: 0px
    }
    to {
        margin-top: 15px;
    }
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}