.search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 75px;
    opacity: 1;
    height: fit-content;
}

.filter-headline {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 10px;
    
    @media screen and (max-width: 700px) { 
        margin-left: 35px;
    }

}

.lookup-name {
    margin-left: 10px;
    margin-right: 10px;

    @media screen and (max-width: 700px) { 
        margin-left: 35px;
        margin-right: 35px;
    }

}

.card {
    img {
        max-height: 200px;
        cursor: pointer;
    }

    &:hover {
        transform: scale(1.05);
    }
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .3s ease-in-out;
    animation: fadeIn .2s alternate;
}

.card-grid {
    border-top: 2px solid #eeeeee;
    margin-top: 35px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 10px;
    justify-content: center;
    min-height: 450px;
}

.close-menu {
    position: absolute;
    cursor: pointer;
    transition: transform .2s ease-in-out;
    &:hover {
        transform: scale(1.10)
    }

    @media screen and (max-width: 700px) { 
        margin-left: 35px;
    }

}

.search-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
}

.search-content {
    margin-top: 15px;
}

.type-section {
    display: flex;
    flex-direction: row;
    
    @media screen and (max-width: 700px) {
        flex-direction: row;
    }

}


.filter-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 600px;
    flex-wrap: wrap;

    @media screen and (max-width: 700px) {
        min-width: unset;
        justify-content: flex-start;
        margin-left: 25px;
    }

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}