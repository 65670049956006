.digit-input {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    input {
        text-align: center;
        width: 40px;
        font-weight: 700;
        font-family: 'Quicksand', sans-serif;
        height: 40px;
        border-radius: 10px;
        font-size: 20px;
        cursor: pointer;
        caret-color: transparent;
        border: 2px solid #eeeeee;
        transition: border .3s ease-out;
        --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    input:focus {
        outline: none;
        border: 2px solid #8B16D3;
        --tw-shadow: 0 10px 15px -3px #8B16D3, 0 4px 6px -2px #8B16D3;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #8B16D3), var(--tw-ring-shadow, 0 0 #8B16D3), var(--tw-shadow);
    }



}