.card-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    opacity: 0;
    background-color: #fafafa;
    animation: fadeIn .5s ease-out forwards;
}

.card-info-modal {
    background-color: #fafafa;
    width: 100%;
}

.card-info-lower {
    display: flex;
    flex-direction: column;
}

.card-info-add-card-section {
    display: flex;
    flex-direction: center;
    align-items: center;
    max-width: 500px;
    justify-content: center;
    margin-bottom: 75px;
}

.card-info-upload-photo {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 75px;
    transition: transform .2s ease-in-out;
    width: 75px;
    &:hover {
        transform: scale(1.20)
    }

    &-svg-container {
        cursor: pointer;
        position: absolute;
        height: 75px;
        width: 75px;
        justify-content: center;
        align-items: center;
        display: flex;

    }

    input {
        opacity: 0;
        position: absolute;
        cursor: pointer;
        width: 75px;
        height: 75px;
    }

}

.card-info-photo-space {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
    max-width: 500px;
}

.card-info-photo-title {
    margin-top: 35px;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
}

.card-info-description {

    &-title {
        font-weight: 600;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 25px;
        font-size: 1.2rem;
    }

    &-text {
        font-weight: 500;
        text-align: center;
        max-width: 500px;
        line-height: 1.9;
    }

}


.card-info-close {
    margin-top: 35px;
    margin-bottom: 35px;
    svg {
        cursor: pointer;
    }

}

.card-info-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-left: 100px;
    margin-right: 100px;
}

.card-info-content {

    margin-top: 75px;

    .card-info-title {
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
    }

}

.card-info-dropdown-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
}

.card-info-dropdowns {
    display: flex;
    flex-direction: row;
}

.card-info-card-photos {
    
    margin-top: 25px;
    margin-bottom: 25px;

    img {
        width: 150px;
        height: 200px;
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 3px;
    }

}

.card-info-image {
    
    display: flex;
    flex-direction: column;
    min-width: 350px;

    img {
        max-height: 500px;
        border-radius: 10px;
        @media screen and (max-width: 700px){
            max-height: 300px;
        }

    }

    @media screen and (max-width: 700px){
        min-width: unset;
    }


}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}